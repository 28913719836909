
// @ts-ignore
import _, { map, find, findIndex } from 'lodash';
// @ts-ignore
import moment from 'moment-timezone';
import naturalSort from 'javascript-natural-sort';

import { Component, Vue, Watch } from 'vue-property-decorator';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import { Action, State, Mutation, Getter } from 'vuex-class';

import { Device, DeviceUserConfig } from '@/types/index.d';

import Card from '@/components/Card.vue';
import CircleBadge from '@/components/CircleBadge.vue';
import DetailPanel from '@/components/DetailPanel.vue';
import DeviceNodVehLinkModal from '@/components/devices/DeviceNodVehLinkModal.vue';
import DeviceRenameModal from '@/components/devices/DeviceRenameModal.vue';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import SidePanelItemBlock from '@/components/nav/SidePanelItemBlock.vue';
import StatusDot from '@/components/StatusDot.vue';
import PreviewScreenshot from '@/components/devices/PreviewScreenshot.vue';
import DevicesMinimal from '@/components/devices/DevicesMinimal.vue';
import DeviceNoLoadingModeSettings from '@/components/devices/DeviceNoLoadingModeSettings.vue';

import {
  deleteDevice,
  putDeviceUserConfig,
  unlinkScreen,
  linkDeviceWithScreen,
  getVehicle,
  unlinkOnBoard,
  resetDevice,
} from '@/services/axios';
import { GOOGLE_MAPS_TOKEN } from '@/services/config';
import { mapConfig } from '@/services/maps';
import CoordinateUtils from '@/utils/coordinate';

@Component({
  components: {
    Card,
    CircleBadge,
    DetailPanel,
    DeviceNodVehLinkModal,
    DeviceRenameModal,
    LMap,
    LMarker,
    LPopup,
    LTileLayer,
    SectionH1,
    SidePanel,
    SidePanelItemBlock,
    SectionHeading,
    StatusDot,
    PreviewScreenshot,
    Vue2LeafletGoogleMutant,
    DevicesMinimal,
    DeviceNoLoadingModeSettings,
  },
})
export default class DevicesView extends Vue {
  // @State devices!: Device[];

  @Action loadDevices!: () => any;

  @State devices!: DeviceUserConfig[];
  @State opId!: number;
  @State screens!: any[];
  @State vehicles!: any[];
  @State operators!: any[];
  @State user!: any;

  @Mutation setDevices!: (devices: any) => void;
  @Getter noLoadingModeAvailable: any;

  isShowingDeviceRenameModal: boolean = false;
  isShowingDeviceLinkModal: boolean = false;
  refreshKey: number = 0;
  selectedDevice: DeviceUserConfig | null = null;
  selectedDeviceIndex: number | null = null;
  selectedVehicle: any | null = null;
  selectedVehicleInfo: any = null;
  selectedScreenId: any = null;
  previousScreenId: any = null;
  ignoreSelectedScreenIdChanges: boolean = false;
  markers: any = [];
  refreshInterval: any = null;
  refreshDelayTimeout: any = null;
  isPreventRefreshInterval: boolean = false;
  isShowingPreviewShotModal: boolean = false;
  noLoadingModeEnabled: boolean = false;

  @Watch('selectedScreenId')
  onSelectedScreenChanged() {
    if (this.ignoreSelectedScreenIdChanges) {
      this.ignoreSelectedScreenIdChanges = false;
    } else {
      this.linkScreen();
    }
  }

  get isMinimal(): boolean {
    if (this.user) {
      return this.user.cms_show_full_menu === false;
    }
    return false;
  }

  get apiKey(): string {
    return GOOGLE_MAPS_TOKEN;
  }

  get mapOptions(): any {
    return mapConfig;
  }

  get mapCenter() {
    if (this.selectedDeviceIndex !== null) {
      const marker = this.markers[this.selectedDeviceIndex!];
      if (marker) {
        if (marker.target._latlng.lat != -999999 && marker.target._latlng.lng != -999999) {
          return [marker.target._latlng.lat, marker.target._latlng.lng];
        } else {
          return null;
        }
      }
    }
    return [13.724043632799845, 100.52558898925783];
  }

  get sortedDevices() {
    const devices = this.devices || [];
    return devices.sort((a, b) => naturalSort(a.name, b.name));
  }

  get vehicleOperatorName() {
    if (this.selectedVehicle) {
      const op = _.find(this.operators, { op_id: this.selectedVehicle.op_id });
      if (op) {
        return op.i18n_name || op.dflt_name || '';
      }
    }
    return null;
  }

  get linkedVehicleName(): string {
    if (
      (this.selectedDevice &&
        this.selectedDevice.on_board &&
        this.selectedDevice.on_board.vhc_id) ||
      this.selectedVehicle
    ) {
      if (this.selectedVehicle) {
        return `${this.selectedVehicle.op_vhc_id} (${this.selectedVehicle.license})`;
      }
      return `เชื่อมต่อแล้ว (ไม่พบรถ)`;
    }
    return 'ยังไม่ได้เชื่อมต่อ';
  }

  get markerIcon(): any {
    const svg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49"> <g fill="none" fill-rule="evenodd"> <path d="M0 0L48 0 48 48 0 48z" transform="translate(0 1)"/> <path fill="#4D6AFF" stroke="#FFF" stroke-linejoin="round" stroke-width="2" d="M24 24c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0-20c8.4 0 16 6.44 16 16.4 0 6.36-4.9 13.84-14.68 22.46-.76.66-1.9.66-2.66 0C12.9 34.24 8 26.76 8 20.4 8 10.44 15.6 4 24 4z" transform="translate(0 1)"/> </g> </svg>';
    const iconUrl = 'data:image/svg+xml;base64,' + btoa(svg);

    return L.icon({
      iconUrl: iconUrl,
      iconSize: [48, 49], // size of the icon
      iconAnchor: [25, 46], //changed marker icon position
      popupAnchor: [0, -36], //changed popup position
    });
  }

  get deviceCoordinates() {
    const coordinates = _.map(this.devices, (device: DeviceUserConfig) => {
      return {
        lat: device.lat ? device.lat : -999999,
        lng: device.lng ? device.lng : -999999,
      };
    });
    return coordinates;
  }

  isDeviceOnline(index: number): boolean {
    if (this.devices && this.devices[index] && this.devices[index].online_at) {
      let diff = moment().diff(moment(`${this.devices[index].online_at}Z`), 'minutes');
      if (diff <= 15) {
        return true;
      }
    }
    return false;
  }

  getDeviceStatusText(index: number): string {
    if (this.devices && this.devices[index] && this.devices[index].online_at) {
      let dateString = moment(`${this.devices[index].online_at}Z`)
        .locale('th')
        .format('D MMMM YYYY เวลา H:mm น.');
      let year = moment(`${this.devices[index].online_at}Z`)
        .locale('th')
        .format('YYYY');
      dateString = dateString.replace(year, this.getBuddhistShortYearString(year));
      const status = this.isDeviceOnline(index) ? 'Online,' : 'Offline,';
      return `${status} ${dateString}`;
    }
    return 'Offline, อุปกรณ์ยังไม่เชื่อมต่อ';
  }
  getLastUploadSSToString(date: Date) {
    return moment(`${date}Z`)
      .locale('th')
      .format('D MMMM YYYY เวลา H:mm น.');
  }
  getLastUploadSSFromNow(date: Date) {
    return moment(`${date}Z`)
      .locale('th')
      .fromNow();
  }

  getBuddhistShortYearString(year: string): string {
    let buddhistYear = (parseInt(year) + 543).toString();
    return buddhistYear.substr(buddhistYear.length - 2);
  }

  getMarkerNumberIcon(index: number): any {
    return L.divIcon({
      html:
        '<div style="display: flex; align-items: center; justify-content: center; font-size: 13px; font-weight: 600; color: white; width: 24px; background: #4d6aff; height: 24px; border-radius: 12px; margin-left: -6px; margin-top: -6px;">' +
        `${index + 1}` +
        '</div>',
      popupAnchor: [0, -12], //changed popup position
    });
  }

  setSelectedVehicle() {
    this.selectedVehicle = null;
    if (
      this.selectedDevice &&
      this.selectedDevice.on_board &&
      this.selectedDevice.on_board.vhc_id &&
      this.selectedDevice.on_board.vhc_op_id
    ) {
      const vhc_id = this.selectedDevice.on_board.vhc_id;
      const vhc_op_id = this.selectedDevice.on_board.vhc_op_id;
      getVehicle(vhc_op_id, vhc_id)
        .then((res: any) => {
          this.selectedVehicle = res.data;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  }

  onVehicleChanged(vehicle: any) {
    this.selectedVehicle = vehicle;
    this.loadDevices();
  }

  closeDeviceRenameModal(): void {
    this.isShowingDeviceRenameModal = false;
    if (!this.selectedDevice) return;
    const device = this.devices.find(dv => dv.usr_id === this.selectedDevice!.usr_id);
    this.selectDevice(device || null, this.selectedDeviceIndex);
    this.isPreventRefreshInterval = false;
  }

  closeDeviceNodVehLinkModal(): void {
    this.isShowingDeviceLinkModal = false;
    this.isPreventRefreshInterval = false;
  }
  openPreviewScreenshot(): void {
    this.isShowingPreviewShotModal = true;
  }
  closePreviewScreenshot(): void {
    this.isShowingPreviewShotModal = false;
  }

  deleteDevice(): void {
    if (!this.selectedDevice) return;

    if (window.confirm('คุณต้องการลบอุปกรณ์นี้หรือไม่?')) {
      this.isPreventRefreshInterval = true;
      deleteDevice(this.opId, this.selectedDevice.usr_id)
        .then(this.loadDevices)
        .then(() => (this.selectedDevice = null))
        .finally(() => {
          this.isPreventRefreshInterval = false;
        });
    }
  }

  resetDevice(usr_id: number): void {
    if (
      window.confirm(
        usr_id ? 'คุณต้องการรีเซ็ตอุปกรณ์นี้หรือไม่?' : 'คุณต้องการรีเซ็ตอุปกรณ์ทั้งหมดหรือไม่'
      )
    ) {
      const deviceUserIds = usr_id ? usr_id : map(this.devices, 'usr_id');
      resetDevice(this.opId, deviceUserIds)
        .then(() => {
          alert('รีเซ็ตสำเร็จแล้ว');
        })
        .catch(() => {
          alert('รีเซ็ตไม่สำเร็จแล้ว กรุณาลองใหม่');
        });
    }
  }

  openDeviceRenameModal(): void {
    this.isPreventRefreshInterval = true;
    this.isShowingDeviceRenameModal = true;
  }

  openDeviceLinkModal(): void {
    this.isPreventRefreshInterval = true;
    this.isShowingDeviceLinkModal = true;
  }

  selectDevice(device: DeviceUserConfig | null, index: number | null) {
    this.selectedDeviceIndex = index;
    this.selectedDevice = device;
    this.setSelectedVehicle();

    this.selectedScreenId = null;
    this.previousScreenId = null;
    if (this.selectedDevice && this.selectedDevice.scrn_id) {
      this.ignoreSelectedScreenIdChanges = true;
      this.selectedScreenId = this.selectedDevice.scrn_id;
      this.previousScreenId = this.selectedDevice.scrn_id;
    }

    if (this.selectedDevice) {
      let query = { device: this.selectedDevice.pub_key };
      this.$router.replace({ query });
    }

    this.focusCurrentDeviceMarker();
  }

  linkScreen() {
    if (
      this.selectedDevice &&
      this.selectedDevice.name &&
      this.selectedDevice.op_id &&
      this.selectedDevice.usr_id &&
      this.selectedScreenId
    ) {
      const preparedDevice = {
        name: this.selectedDevice.name,
        scrn_id: this.selectedScreenId,
        is_act: true,
      };

      if (this.selectedDevice.scrn_id === null) {
        const body = {
          cfg: [
            {
              usr_id: this.selectedDevice.usr_id,
              scrn_id: this.selectedScreenId,
              is_act: true,
            },
          ],
        };
        linkDeviceWithScreen(this.selectedDevice.op_id, body)
          .then(() => {
            this.previousScreenId = this.selectedScreenId;
            this.selectedDevice!.scrn_id = this.selectedScreenId;
            this.updateDeviceState();
          })
          .catch(err => {
            alert('เลือกหน้าจอไม่สำเร็จ กรุณาลองใหม่');
            this.ignoreSelectedScreenIdChanges = true;
            this.selectedScreenId = this.previousScreenId;
          });
      } else {
        putDeviceUserConfig(this.selectedDevice.op_id, this.selectedDevice.usr_id, preparedDevice)
          .then(() => {
            this.previousScreenId = this.selectedScreenId;
            this.selectedDevice!.scrn_id = this.selectedScreenId;
            this.updateDeviceState();
          })
          .catch(err => {
            alert('เลือกหน้าจอไม่สำเร็จ กรุณาลองใหม่');
            this.ignoreSelectedScreenIdChanges = true;
            this.selectedScreenId = this.previousScreenId;
          });
      }
    }
  }

  unlinkScreen() {
    if (this.selectedDevice && this.selectedDevice.op_id && this.selectedDevice.usr_id) {
      unlinkScreen(this.selectedDevice.op_id, this.selectedDevice.usr_id)
        .then(() => {
          this.selectedScreenId = null;
          this.previousScreenId = null;
          this.selectedDevice!.scrn_id = null;
          this.updateDeviceState();
        })
        .catch(err => {
          alert('Unlink ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
        });
    }
  }

  unlinkOnBoard() {
    if (this.selectedDevice && this.selectedDevice.op_id && this.selectedDevice.usr_id) {
      this.isPreventRefreshInterval = true;
      unlinkOnBoard(this.selectedDevice.op_id, this.selectedDevice.usr_id)
        .then(() => {
          this.selectedVehicle = null;
          this.loadDevices();
        })
        .catch(err => {
          alert('Unlink ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
        })
        .finally(() => {
          this.isPreventRefreshInterval = false;
        });
    }
  }

  updateDeviceState() {
    if (this.selectedDeviceIndex && this.selectedDevice) {
      let newDevices = _.cloneDeep(this.devices);
      newDevices[this.selectedDeviceIndex] = this.selectedDevice;
      this.setDevices(newDevices);
    }
  }

  onMarkerAdded(event: any) {
    this.markers.push(event);
  }

  focusCurrentDeviceMarker() {
    _.forEach(this.markers, (m: any) => {
      m.target.closePopup();
    });

    let marker = this.markers[this.selectedDeviceIndex!];
    if (marker) {
      if (marker.target._latlng.lat != -999999 && marker.target._latlng.lng != -999999) {
        marker.target.openPopup();
      }
    }
  }

  get6FractionDigitsString(number: number) {
    return CoordinateUtils.get6FractionDigitsString(number);
  }

  selectDeviceFromQueryIfNeeded() {
    const pubKey = this.$route.query.device;
    if (pubKey) {
      let deviceIndex = findIndex(this.devices, (device: DeviceUserConfig) => {
        return device.pub_key === pubKey;
      });
      if (deviceIndex >= 0) {
        // @ts-ignore
        this.selectDevice(this.devices[deviceIndex], deviceIndex);
      } else {
        this.$router.replace({ query: {} });
      }
    }
  }

  initRefreshInterval() {
    this.refreshInterval = setInterval(() => {
      if (!this.isPreventRefreshInterval) {
        this.loadDevices()
          .then(() => {
            this.isPreventRefreshInterval = true;
          })
          .catch(() => {})
          .finally(() => {
            this.isPreventRefreshInterval = false;
          });
      }
    }, 1000 * 30);
  }

  resumeRefreshing() {
    this.refreshDelayTimeout = setTimeout(() => {
      this.loadDevices()
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.initRefreshInterval();
        });
    }, 1000 * 10);
  }

  clearRefreshInterval() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    clearTimeout(this.refreshDelayTimeout);
    this.refreshDelayTimeout = null;
  }

  setDeviceNoLoadingMode(isEnabled: boolean) {
    if (this.selectedDevice && this.selectedDeviceIndex) {
      this.selectedDevice.no_loading_mode_enable = isEnabled;
      this.updateDeviceState();
    }
  }

  mounted() {
    this.isPreventRefreshInterval = true;

    this.loadDevices()
      .then(() => {
        this.selectDeviceFromQueryIfNeeded();
      })
      .catch(() => {})
      .finally(() => {
        this.isPreventRefreshInterval = false;
      });
    this.initRefreshInterval();
  }

  destroyed() {
    this.clearRefreshInterval();
  }
}
