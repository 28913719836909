
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceUserConfig } from '@/types/index.d';
import { getLastUpdateTimeFromDateString } from '@/utils/time';

@Component({
  components: {},
})
export default class DeviceMinimalItem extends Vue {
  @Prop() device!: DeviceUserConfig;
  @Prop() isHdmiError!: boolean;
  @Prop() isOnline!: boolean;
  @Prop() isOffline!: boolean;

  getLastOnlineTimeOfDevice(device: DeviceUserConfig) {
    return getLastUpdateTimeFromDateString(device.online_at, !this.isOnline, true);
  }

  onDeviceNameMouseEnter(event: any) {
    const ref: any = this.$refs.deviceName;
    if (ref) {
      const isOverflow = this.isOverflow(ref);
      if (isOverflow) {
        this.$emit('onDeviceMinimalItemMouseEnter', this.device, ref.getBoundingClientRect());
      }
    }
  }

  onDeviceNameMouseLeave(event: any) {
    this.$emit('onDeviceMinimalItemMouseLeave');
  }

  isOverflow(ref: any) {
    if (ref) {
      return ref.scrollWidth > ref.clientWidth;
    }
    return false;
  }
}
