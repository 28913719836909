
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { orderBy, filter, chunk, times } from 'lodash';
import moment from 'moment-timezone';
import { ToggleButton } from 'vue-js-toggle-button';
import { DeviceUserConfig } from '@/types/index.d';
import SearchTextField from '@/components/form/SearchTextField.vue';
import VBDropdownSearch from '@/components/custom-views/vb-dropdown-search/classes/vb-dropdown-search.vue';
import DeviceMinimalItem from '@/components/devices/DeviceMinimalItem.vue';

const MIN_DEVICES_PER_PAGE = 24;
const MIN_DEVICES_PER_COLUMN = 8;
const DEVICE_ITEM_HEIGHT = 56;
const NUMBER_COLUMNS = 4;

@Component({
  components: { SearchTextField, VBDropdownSearch, DeviceMinimalItem, ToggleButton },
})
export default class DevicesMinimal extends Vue {
  @State devices!: DeviceUserConfig[];
  @State opId!: number;

  selectedSortItem: any = this.sortItemList[0];
  selectedFilterItem: any = this.filterItemList[0];
  searchText: string = '';
  currentPage: number = 0;
  devicesPerColumn: number = MIN_DEVICES_PER_COLUMN;
  devicesPerPage: number = MIN_DEVICES_PER_PAGE;

  mouseEnteredDevice: DeviceUserConfig | null = null;
  mouseEnteredDeviceRect: any = null;

  isRefreshingEnabled: boolean = true;

  @Watch('devices')
  onDevicesChanged() {
    this.mouseEnteredDevice = null;
  }

  @Watch('isRefreshingEnabled')
  onIsRefreshingEnabled() {
    if (this.isRefreshingEnabled) {
      this.$emit('resumeRefreshing');
    } else {
      this.$emit('stopRefreshing');
    }
  }

  get deviceCount() {
    return this.filteredSearchingDevices.length;
  }

  get onlineDevices() {
    return filter(this.filteredSearchingDevices, (device: DeviceUserConfig) =>
      this.isDeviceOnline(device)
    );
  }
  get offlineDevices() {
    return filter(this.filteredSearchingDevices, device => !this.isDeviceOnline(device));
  }
  get hdmiErrorDevices() {
    return filter(this.filteredSearchingDevices, device => this.isDeviceHDMIError(device));
  }

  get sortedDevices() {
    const orders: { [key: string]: any } = {
      name: 'asc',
      online_at: 'desc',
    };

    return orderBy(this.devices, this.selectedSortItem.key, orders[this.selectedSortItem.key]);
  }

  get filteredSearchingDevices() {
    let devices: DeviceUserConfig[] = this.sortedDevices;

    devices = filter(devices, (device: DeviceUserConfig) => {
      return device.name.toLowerCase().includes(this.searchText.toLowerCase());
    });

    return devices;
  }

  get filteredDevices() {
    switch (this.selectedFilterItem.key) {
      case 'online':
        return this.onlineDevices;
      case 'offline':
        return this.offlineDevices;
      case 'hdmi-error':
        return this.hdmiErrorDevices;
      default:
        return this.filteredSearchingDevices;
    }
  }

  get columns() {
    return times(NUMBER_COLUMNS, i => {
      return i;
    });
  }

  get isOnLastPage() {
    return this.currentPage + 1 == this.totalPage;
  }

  get isOnFirstPage() {
    return this.currentPage == 0;
  }

  get totalPage() {
    return Math.ceil(this.filteredDevices.length / this.devicesPerPage);
  }

  get paginatedDevices() {
    const chunkedDevices = chunk(this.filteredDevices, this.devicesPerPage);
    const currentPageDevices = chunkedDevices[this.currentPage];
    const chunkColumnDevices = chunk(currentPageDevices, this.devicesPerColumn);
    return chunkColumnDevices;
  }

  get onlineDeviceCount() {
    return this.onlineDevices.length;
  }

  get offlineDeviceCount() {
    return this.offlineDevices.length;
  }

  get hdmiErrorDeviceCount() {
    return this.hdmiErrorDevices.length;
  }

  get filterItemList() {
    return [
      {
        id: 0,
        title: 'ทั้งหมด',
      },
      {
        id: 1,
        title: 'Online',
        key: 'online',
      },
      {
        id: 2,
        title: 'Offline',
        key: 'offline',
      },
      {
        id: 3,
        title: 'HDMI ขัดข้อง',
        key: 'hdmi-error',
      },
    ];
  }

  get sortItemList() {
    return [
      {
        id: 0,
        title: 'ออนไลน์ล่าสุด',
        key: 'online_at',
      },
      {
        id: 1,
        title: 'ชื่อกล่อง',
        key: 'name',
      },
    ];
  }

  isSelectedFilterItem(item: any) {
    return this.selectedFilterItem.id === item.id;
  }

  selectFilterItem(item: any) {
    if (this.selectedFilterItem.id !== item.id) {
      this.selectedFilterItem = item;
      this.currentPage = 0;
    }
  }

  onSelectSortItem(item: any) {
    if (this.selectedSortItem.id !== item.id) {
      this.selectedSortItem = item;
      this.currentPage = 0;
    }
  }

  onSearchTextChange(text: string) {
    this.searchText = text;
    this.currentPage = 0;
  }

  addPage(page: number) {
    this.currentPage += page;
  }

  isDeviceOnline(device: DeviceUserConfig): boolean {
    if (device.online_at) {
      let diff = moment().diff(moment(`${device.online_at}Z`), 'minutes');
      if (diff <= 15) {
        return true;
      }
    }
    return false;
  }

  isDeviceHDMIError(device: DeviceUserConfig): boolean {
    return this.isDeviceOnline(device) && !device.is_hdmi_turn_on;
  }

  onDeviceMinimalItemMouseEnter(device: DeviceUserConfig, rect: any) {
    this.mouseEnteredDevice = device;
    this.mouseEnteredDeviceRect = rect;
  }

  onDeviceMinimalItemMouseLeave() {
    this.mouseEnteredDevice = null;
  }

  updatePageSize() {
    const ref: any = this.$refs.dvmContent;
    if (ref) {
      this.currentPage = 0;
      const rect: any = ref.getBoundingClientRect();
      const height: number = rect.height - 30;
      let numberOfDevicesPerColumn = Math.floor(height / DEVICE_ITEM_HEIGHT);
      if (numberOfDevicesPerColumn < MIN_DEVICES_PER_COLUMN) {
        numberOfDevicesPerColumn = MIN_DEVICES_PER_COLUMN;
      }
      this.devicesPerPage = numberOfDevicesPerColumn * NUMBER_COLUMNS;
      this.devicesPerColumn = numberOfDevicesPerColumn;
    }
  }

  mounted() {
    this.updatePageSize();
    window.addEventListener('resize', this.updatePageSize);
  }

  destroyed() {
    window.removeEventListener('resize', this.updatePageSize);
  }
}
