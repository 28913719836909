
import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import { AxiosResponse } from 'axios';

// @ts-ignore
import { login } from '@/services/auth';
import { CURRENT_TOKEN, REFRESH_TOKEN, USR } from '@/services/config';
import { ViaAuth } from '@/types/index.d';
import { getOperators } from '@/services/axios';

@Component({})
export default class LoginView extends Vue {
  @Action loadData!: () => void;
  @Mutation setOpId!: (opId: number) => void;
  @Mutation setDeviceKey!: (key: string | null) => void;
  @Mutation setUserId!: (userId: string | null) => void;

  @State deviceKey!: string;

  operators: any[] = [];
  selectedOperator: number = 0;
  username: string = '';
  password: string = '';

  get mappedOperators(): any[] {
    return this.operators.sort((a, b) => a.op_id - b.op_id);
  }

  getOperators() {
    return getOperators();
  }

  logIn(): void {
    if (!this.username || !this.password) return;

    login(this.username, this.password)
      .then((res: ViaAuth) => {
        localStorage.setItem(CURRENT_TOKEN, res.auth.token);
        localStorage.setItem(REFRESH_TOKEN, res.auth.refresh_token);
        localStorage.setItem(USR, res.auth.usr);
      })
      .then(this.getOperators)
      .then((res: any) => {
        this.operators = res.data;
      });

    // .then((res: any) => (this.operators = res.data.filter((op: any) => op.op_id === 54)));
  }

  setStateUserId() {
    let userId = localStorage.getItem(USR);
    if (userId) {
      this.setUserId(userId);
    }
  }

  selectOperator(): void {
    this.setOpId(this.selectedOperator);
    this.setStateUserId();
    this.loadData();

    if (this.deviceKey) {
      // @ts-ignore
      this.$router.push({
        name: 'devices-link-view',
        query: {
          key: this.deviceKey,
        },
      });
      this.setDeviceKey(null);
    } else {
      // @ts-ignore
      this.$router.push({
        name: 'devices-view',
        params: {
          opId: this.selectedOperator,
        },
      });
    }
  }

  mounted() {
    const currentToken = localStorage.getItem(CURRENT_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const usr = localStorage.getItem(USR);

    if (currentToken) localStorage.removeItem(CURRENT_TOKEN);
    if (refreshToken) localStorage.removeItem(REFRESH_TOKEN);
    if (usr) localStorage.removeItem(USR);
  }
}
